import {
  useState,
  createContext,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { FetchContext } from "../FetchContext/FetchContext";
import { apisUrl, apiKey } from "../../environment/config";
import { useHistory } from "react-router-dom";

export const ApiDocsContext = createContext();

const ApiDocsContextProvider = ({ children }) => {
  const history = useHistory();
  const fetchContext = useContext(FetchContext);
  const [apiDocs, setApiDocs] = useState(null);
  const [apiVersion, setApiVersion] = useState("v1");

  const requestApisListing = useCallback(() => {
    return fetchContext.authAxios.get(
      `${apisUrl}/${apiVersion}/apis-service/apis/specifications?key=${apiKey}`
    );
  }, [fetchContext.authAxios, apiVersion]);

  const requestApiDoc = useCallback((api) => {
    return fetchContext.authAxios.get(
      `${apisUrl}/${apiVersion}/apis-service/apis/specifications/${api}?key=${apiKey}`
    );
  }, [fetchContext.authAxios, apiVersion]);


  useEffect(() => {
    requestApisListing()
      .then((response) => {
        const apiPromises = response.data.map((api) => 
          requestApiDoc(api)
            .then((response) => response.data)
            .catch((err) => null)
        );
      
        return Promise.allSettled(apiPromises);
      })
      .then((results) => {
        const successfulResults = results
          .filter((result) => result.status === 'fulfilled' && result.value !== null)
          .map((result) => result.value);
      
        setApiDocs(successfulResults);
      })
      .catch((err) => {
        if (err?.response?.status === 403 || err?.response?.status === 401) {
          history.push("/login");
        } else if (err && apiVersion !== "v1") {
          setApiVersion("v1");
        } else {
          setApiDocs([]);
        }
      });
  }, [requestApisListing, history, apiVersion, requestApiDoc]);

  return (
    <ApiDocsContext.Provider value={{ apiDocs, apiVersion }}>
      {children}
    </ApiDocsContext.Provider>
  );
};

export default ApiDocsContextProvider;
