import { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import { AuthUserContext } from '../../../context/AuthUser/AuthUserContext';

import AuthWrapper from '../AuthWrapper/AuthWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const history = useHistory();
  const { resetPassword } = useContext(AuthUserContext);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ email }) => {
    try {
      setLoading(true);
      await resetPassword(email);
      setLoading(false);
      history.push('/auth/reset-success');
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.msg || err?.response?.data.message || err.message,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      setLoading(false);
    }
  };

  return (
    <AuthWrapper title="Reset Password">
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              helperText={errors.email ? errors.email.message : null}
              inputRef={register({
                required: 'Email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid e-mail address',
                },
              })}
              error={!!errors?.email}
              autoComplete="email"
              disabled={isLoading}
              autoFocus
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={isLoading}
        >
          Reset
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <MuiLink component={Link} to="/auth" color="primary">
              Already have an account? Sign In
            </MuiLink>
          </Grid>
        </Grid>
      </form>
    </AuthWrapper>
  );
};

export default ResetPassword;
