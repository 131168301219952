import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthUserContext } from '../context/AuthUser/AuthUserContext';

function PrivateRoutes({ component: Component, ...rest }) {
  const { authState } = useContext(AuthUserContext);

  return (
    <Route
      {...rest}
      render={props => {
        return authState.token ? <Component {...props} /> : <Redirect to="/" />;
      }}
    ></Route>
  );
}

export default PrivateRoutes;
