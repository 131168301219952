import { memo } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/reitangruppen-logo_gold2.svg';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  brand: {
    margin: theme.spacing(1),
  },
}));

const AuthWrapper = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <span className={classes.brand}>
          {' '}
          <img src={Logo} alt="Reitan Logo" height="105" width="105" />
        </span>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {children}
      </div>
    </Container>
  );
};

export default memo(AuthWrapper);
