export const identityUrl =
  process.env.REACT_APP_IDENTITY_URL;

export const apisUrl =
  process.env.REACT_APP_APIS_URL;

export const apiKey =
  process.env.REACT_APP_API_KEY;

export const roles = {
  admin: 'api_admin'
};
