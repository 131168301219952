import { useState, useContext, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';

import { AuthUserContext } from '../../../context/AuthUser/AuthUserContext';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.main,
  },
}));

function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const { register: useFormRegister, handleSubmit, errors, watch } = useForm();
  const classes = useStyles();
  const { register } = useContext(AuthUserContext);
  const history = useHistory();

  const password = useRef({});
  password.current = watch('password', '');
  const [loading, setLoading] = useState(false);

  async function onSubmit({
    firstName,
    lastName,
    phone,
    company,
    email,
    password,
  }) {
    try {
      setLoading(true);
      await register(firstName, lastName, phone, company, email, password);
      history.push('/thank-you');
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.msg || err?.response?.data.message || err.message,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }

    setLoading(false);
  }

  return (
    <AuthWrapper title="Sign Up">
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              fullWidth
              id="firstName"
              label="First Name"
              inputRef={useFormRegister}
              disabled={loading}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              inputRef={useFormRegister}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="phone"
              name="phone"
              variant="outlined"
              fullWidth
              id="phone"
              type="number"
              inputRef={useFormRegister}
              label="Phone"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="company"
              label="Company"
              name="company"
              autoComplete="company"
              inputRef={useFormRegister}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              inputRef={useFormRegister({
                required: 'Email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid e-mail address',
                },
              })}
              helperText={errors.email ? errors.email.message : null}
              error={!!errors?.email}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={useFormRegister({
                required: 'Password is required',
              })}
              helperText={errors.password ? errors.password.message : null}
              error={!!errors?.password}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="confirm-password"
              inputRef={useFormRegister({
                required: 'Please confirm your password',
                validate: value =>
                  value === password.current || 'The passwords do not match',
              })}
              helperText={
                errors.confirmPassword ? errors.confirmPassword.message : null
              }
              error={!!errors?.confirmPassword}
              disabled={loading}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={loading}
        >
          Sign Up
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <MuiLink component={Link} to="/auth" color="primary">
              Already have an account? Sign In
            </MuiLink>
          </Grid>
        </Grid>
      </form>
    </AuthWrapper>
  );
}

export default SignUp;
