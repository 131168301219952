import { useState, createContext, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FetchContext } from "../FetchContext/FetchContext";
import { apisUrl, apiKey } from "../../environment/config";
import createApiName from "../../utils/createApiName";

export const ApisContext = createContext();

const ApisContextProvider = ({ children }) => {
  const history = useHistory();
  const fetchContext = useContext(FetchContext);
  const [apis, setApis] = useState([]);
  const [pendingApis, setPendingApis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPendingApis, setLoadingPendingApis] = useState(false);
  const [apiVersion, setApiVersion] = useState('v1')

  const getApis = useCallback(() => {
    return fetchContext.authAxios.get(
      `${apisUrl}/${apiVersion}/apis-service/apis?key=${apiKey}`
    );
  }, [fetchContext.authAxios, apiVersion]);



  const getApisWithLoading = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getApis();
      const processedData = data?.filter(api => !api?.disabled) //removes disabled api:s from list of requests.
      const usedData = processedData.map((api) => ({...api, name: createApiName(api.id)}))
      setApis(usedData);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 403 || err?.response?.status === 401) {
        history.push("/login");
      } else if (err && apiVersion !== 'v1') {
        setApiVersion('v1')
      }
    }
  }, [getApis, history, apiVersion]);

  const requestPendingApis = useCallback(() => {
    return fetchContext.authAxios.get(
      `${apisUrl}/${apiVersion}/apis-service/apis/access/requests?key=${apiKey}`
    );
  }, [fetchContext.authAxios, apiVersion]);



  const getPendingApisWithLoading = useCallback(async () => {
    setLoadingPendingApis(true);
    try {
      const { data } = await requestPendingApis();


      setPendingApis(data);
      setLoadingPendingApis(false);
    } catch (err) {
      setLoadingPendingApis(false);

      if (err?.response?.status === 403 || err?.response?.status === 401) {
        history.push("/login");
      }  else if (err && apiVersion !== 'v1') {
        setApiVersion('v1')
      }
    }
  }, [history, requestPendingApis, apiVersion]);

  const requestApiApproval = useCallback(
    async (data) => {
      return fetchContext.authAxios.post(
        `${apisUrl}/${apiVersion}/apis-service/apis/access/requests?key=${apiKey}`,
        {
          api: data,
        }
      );
    },
    [fetchContext.authAxios, apiVersion]
  );

  const removeApiAccess = useCallback(
    async (data) => {
      return fetchContext.authAxios.post(
        `${apisUrl}/${apiVersion}/apis-service/apis/access/delete?key=${apiKey}`,
        {
          api: data,
        }
      );
    },
    [fetchContext.authAxios, apiVersion]
  );

  const controlApiAccess = useCallback(
    async ({ apiId, isApproved }) => {
      return fetchContext.authAxios.post(
        `${apisUrl}/${apiVersion}/apis-service/apis/access/${apiId}?key=${apiKey}`,
        {
          is_approved: isApproved,
        }
      );
    },
    [fetchContext.authAxios, apiVersion]
  );

  // const enableApi = async id => later(3000, `Enabled ${id}`);

  // const disableApi = async id => later(3000, `Disabled ${id}`);

  return (
    <ApisContext.Provider
      value={{
        loading,
        apis,
        getApis,
        pendingApis,
        loadingPendingApis,
        getPendingApisWithLoading,
        setPendingApis,
        requestPendingApis,
        requestApiApproval,
        getApisWithLoading,
        setApis,
        controlApiAccess,
        removeApiAccess,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
};

export default ApisContextProvider;
