import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      '-apple-system',
      '"Roboto"',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'light',
    primary: {
      main: '#bf845c',
    },
    secondary: {
      main: '#161527',
    },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: '#bf845c',
        '&:hover': {
          backgroundColor: '#444352',
        },
      },
    },
    MuiBadge: {
      colorSecondary: {
        color: '#bf845c',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#bf845c',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#bf845c',
        '&$checked': {
          color: '#bf845c',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#bf845c',
        '&$checked': {
          color: '#bf845c',
        },
      },
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
