import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 375,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ResetPasswordSuccess() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.pos} color="textSecondary">
          Check Your Email
        </Typography>
        <Typography variant="body2" component="p">
          We have e-mailed your password reset link!
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          fullWidth
          variant="contained"
          color="secondary"
          size="small"
          to="/auth"
        >
          Sign In
        </Button>
      </CardActions>
    </Card>
  );
}

export default ResetPasswordSuccess;
