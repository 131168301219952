import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthUserContextProvider from './context/AuthUser/AuthUserContext';
import ApisContextProvider from './context/Apis/ApisContext';
import { FetchProvider } from './context/FetchContext/FetchContext';

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Router>
    <AuthUserContextProvider>
      <FetchProvider>
        <ApisContextProvider>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            TransitionComponent={Slide}
            action={key => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <App />
          </SnackbarProvider>
        </ApisContextProvider>
      </FetchProvider>
    </AuthUserContextProvider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
