import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import LoginPage from '../pages/Auth/LoginPage/index';
import PrivateRoutes from './privateRoutes';
import { makeStyles } from '@material-ui/core';
import ApiDocsContextProvider from "../context/ApiDocsContext/ApiDocsContext";

const ThankYou = lazy(() => import('../pages/ThankYou'));
const DashboardLayout = lazy(() => import('../layout/DashboardLayout'));

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Routes = () => {
  const classes = useStyles();

  return (
    <Switch>
      <Route path="/auth" component={LoginPage} />
      <Route
        exact
        path="/thank-you"
        component={() => (
          <Suspense
            fallback={
              <div className={classes.loaderContainer}>
                <CircularProgress size="4rem" />
              </div>
            }
          >
            <ThankYou />
          </Suspense>
        )}
      />
      <PrivateRoutes
        path="/dashboard"
        component={() => (
          <ApiDocsContextProvider>
            <Suspense
              fallback={
                <div className={classes.loaderContainer}>
                  <CircularProgress size="4rem" />
                </div>
              }
            >
              <DashboardLayout />
            </Suspense>
          </ApiDocsContextProvider>
        )}
      />
      {/* <Route path="/mainlayout" component={DashboardLayout} /> */}
      <Redirect from="/" to="/auth" />
    </Switch>
  );
};

export default Routes;
