import { Switch, Route, useRouteMatch } from "react-router-dom";

import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ResetPassword from "../ResetPassword";
import ResetPasswordSuccess from "../ResetPassword/ResetPasswordSuccess";

//function AuthForm() {
function AuthorizationRoute() {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path} component={SignIn} />
        <Route path={`${path}/:reset-password`} component={ResetPassword} />
        <Route
          path={`${path}/:reset-success`}
          component={ResetPasswordSuccess}
        />
        <Route path={`${path}/:signup`} component={SignUp} />
      </Switch>
    </div>
  );
}

export default AuthorizationRoute;
