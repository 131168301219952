import { useState, createContext, useContext } from 'react';
import axios from 'axios';
import jwt from 'jwt-decode';
import { identityUrl, apiKey, roles } from '../../environment/config';
import jwtDecode from 'jwt-decode';

export const AuthUserContext = createContext();
export const useAuthUserContext = () => useContext(AuthUserContext)

const AuthUserContextProvider = ({ children }) => {
  const token = localStorage.getItem('token');

  const userInfo = localStorage.getItem('user');
  const [authState, setAuthState] = useState({
    token,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
    saUser: token ? jwtDecode(token)?.serviceaccount_user : false
  });

  async function login(email, password) {
    let singUserIn = await axios
      .post(identityUrl + '/v1/reip/account/signin', {
        email: email,
        password: password,
      })
      .then(resp => {
        const token = resp.data.jwt;
        const user = jwt(token);

        localStorage.setItem('token', token);
        localStorage.setItem(
          'user',
          JSON.stringify({
            email: user.email,
            admin: user[roles.admin],
            picture: user.picture,
            auth_time: user.auth_time,
          })
        );
        setAuthState({
          token,
          saUser: token ? jwtDecode(token)?.serviceaccount_user : false,
          userInfo: {
            email: user.email,
            admin: user[roles.admin],
            picture: user.picture,
            auth_time: user.auth_time,
            
          },
        });
        return user;
      });

    return singUserIn;
  }

  // LOGOUT
  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthState({
      token: null,
      userInfo: {},
    });
    return true;
  }

  // REGISTER NEW USER
  async function register(
    firstName,
    lastName,
    phoneNumber,
    company,
    email,
    password
  ) {
    return axios.post(
      identityUrl + '/v1/reip/supplier/register?key=' + apiKey,
      {
        firstName,
        lastName,
        phoneNumber,
        company,
        email,
        password,
      }
    );
  }

  // RESET PASSWORD
  async function resetPassword(email) {
    return axios.post(identityUrl + '/v1/reip/account/password/reset', {
      email: email,
    });
  }

  return (
    <AuthUserContext.Provider
      value={{
        login,
        logout,
        register,
        resetPassword,
        authState,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserContextProvider;
