import { memo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import ReitanWelcomeIMG from '../../../assets/images/ReitanWelcomeIMG.png';
import ReitanWelcomeIMGRotated from '../../../assets/images/rotated_ReitanWelcomeIMG.png';

import AuthRoute from '../AuthRoute';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ReitanWelcomeIMG})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${ReitanWelcomeIMGRotated})`,
    },
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10%',
    marginBottom: theme.spacing(3),
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <AuthRoute />
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(LoginPage);
