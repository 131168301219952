import { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';

import { AuthUserContext } from '../../../context/AuthUser/AuthUserContext';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.main,
  },
}));

export default function SignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const { login } = useContext(AuthUserContext);

  let { url } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // error handling

  const [isSubscribed, setIsSubscribed] = useState();

  const onSubmit = async ({ email, password }) => {
    if (password && email) {
      try {
        setLoading(true);
        setIsSubscribed(true);
        let signin = await login(email, password);
        if (signin) {
          history.push('/dashboard');
          if (isSubscribed) {
            setIsSubscribed(false);
          }
        }
      } catch (err) {
        enqueueSnackbar(
          err?.response?.data?.msg ||
            err?.response?.data.message ||
            err.message,
          {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsSubscribed(true);
    return () => {
      setIsSubscribed(false);
    };
  }, []);

  return (
    <AuthWrapper title="Sign In">
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          helperText={errors.email ? errors.email.message : null}
          inputRef={register({
            required: 'Email address is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid e-mail address',
            },
          })}
          error={!!errors?.email}
          disabled={loading}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          inputRef={register({
            required: 'Password is required',
          })}
          helperText={errors.password ? errors.password.message : null}
          error={!!errors?.password}
          disabled={loading}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={loading}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <MuiLink
              component={Link}
              to={`${url}/reset-password`}
              color="primary"
            >
              {' Forgot password?'}
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to={`${url}/signup`} color="primary">
              {"Don't have an account? Sign Up"}
            </MuiLink>
          </Grid>
        </Grid>
      </form>
    </AuthWrapper>
  );
}
