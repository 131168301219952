
const createApiName = (api) => {
    return api.split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('_')
    .map((word, index, words) => {
      if (index === words.length - 1) {
        // If this is the last word, make it all uppercase
        return word.toUpperCase();
      } else {
        // Otherwise, capitalize the first letter and make the rest lowercase
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
  }

  export default createApiName;